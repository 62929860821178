.imagePreview :global(.ant-image-preview-switch-left) {
  inset-inline-start: 64px;
  background-color: #66ccff;
}
.imagePreview :global(.ant-image-preview-switch-right) {
  inset-inline-end: 64px;
  background-color: #66ccff;
}

.imagePreview :global(.ant-image-preview-switch-right-disabled),
.imagePreview :global(.ant-image-preview-switch-left-disabled) {
  opacity: 0.5;
}

.drawerHeader :global(.ant-drawer-header-title) {
  justify-content: flex-end;
}

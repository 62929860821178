@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('legacy-components/pdf/document-viewer.css');
@import url('legacy-pages/renter/schedule-tour/schedule-calendar.css');
@import url('legacy-components/fields/custom-datepicker/custom-datepicker.css');
@import url('legacy-components/ui/carousel/carousel.css');
@import url('legacy-components/fields/phone-input/phone-input.css');
@import url('legacy-components/tours-schedule/calendar/tours-schedule-calendar.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.border-primary {
  border: 1px solid #66ccff;
}

.border-primary-checked {
  border: 2px solid #66ccff;
}

.border-gray {
  border: 1px solid #f0f0f0;
}

.quill .ql-toolbar {
  display: none;
}

.quill .ql-container {
  transition: all 0.3s;
  min-height: 100px;
  border-radius: 0.375rem;
  border: 1px solid rgb(229 229 229) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.quill .ql-container:hover {
  border-color: #66ccff !important;
}

.quill .ql-editor {
  padding: 0.5rem 0.75rem;
  /*word-break: break-all*/;
}

.react-datepicker-wrapper {
  width: 100%;
}

.simplebar-scrollbar::before {
  background-color: #66ccff;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-track.simplebar-horizontal {
  background-color: #F3F3F3;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f0f0f0;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #66ccff;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #0996ce;
  }

  /*.slider::-webkit-slider-thumb:hover {*/
  /*    background: darkgoldenrod;*/
  /*    opacity: 1 !important;*/
  /* /*} */
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #ffffff;
  color: #6c727f;
  border-radius: 8px;
  border: 1px solid var(--Dust-Red-1, #fff1f0);
  background: var(--Neutrals-White, #fff);

  /* 1.6 em */
  box-shadow: 0px 5px 40px 4px rgba(196, 196, 196, 0.15);
}

.cursor-pointer {
  cursor: pointer;
}
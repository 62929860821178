.react-tel-input {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.flag-dropdown:hover .phone-input {
  border-color: #66ccff !important;
}

.react-tel-input:hover .phone-input {
  border-color: #66ccff !important;
}

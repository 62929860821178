.PDF-view-container {
  background-color: white;
  display: flex;
  max-width: fit-content;
  margin: 0 auto;
  border-radius: 16px;
  overflow-y: auto;
  box-shadow: 0px 2px 56px -2px rgba(102, 204, 255, 0.1);
  box-shadow: 0px 4px 24px -1px rgba(102, 204, 255, 0.2);
  max-height: 792px;
}

.PDF-view-container:has(.PDF-error-placeholder) {
  max-width: initial;
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.PDF-error-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 2rem;
}

.PDF-view-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDF-view-container .react-pdf__Page {
  /* max-width: calc(100% - 2em); */
}

.PDF-view-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.PDF-view-container .react-pdf__message {
  padding: 20px;
  color: white;
}

#drawArea {
  /* FOR DRAWING NEED TO HAVE STATIC WIDTH/HEIGHT -!!! */
  position: relative;
  width: 612px;
  height: 792px;
}

svg.PDF-draw-image {
  z-index: 1;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.PDF-draw-line {
  fill: none;
  stroke: rgb(0, 0, 1);
  stroke-linejoin: round;
  stroke-linecap: round;
}

.TextOverflow {
  display: block;
  display: -webkit-box;
  max-width: 876px;
  font-size: 1rem;
  line-height: 25.6px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  color: rgb(108 108 108);
}

.ReadMore {
  height: auto;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
}

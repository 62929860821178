.mui-datepicker .MuiInputBase-root {
  height: 44px;
  border-radius: 6px;
  background-color: white;
}

.mui-datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: rgb(229 229 229);
}

.mui-datepicker .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #66ccff;
}

.mui-datepicker .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: #66ccff;
}

.mui-datepicker .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff385c !important;
}

.mui-datepicker .MuiInputBase-root:hover.Mui-error .MuiOutlinedInput-notchedOutline,
.mui-datepicker .MuiInputBase-root.Mui-error.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff385c !important;
}

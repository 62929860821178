.carousel .thumbs {
  display: flex;
}

.carousel .thumbs .thumb {
  display: block;
  height: 80px;
  overflow: hidden;
  object-fit: contain;
}

.carousel .thumbs .thumb img {
  width: 100%;
  height: 100% !important;
}

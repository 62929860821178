.tours-schedule-calendar .rbc-row-bg,
.tours-schedule-calendar .rbc-header,
.tours-schedule-calendar .rbc-month-view,
.tours-schedule-calendar .rbc-month-row,
.tours-schedule-calendar .rbc-day-bg {
  border: none;
}

.tours-schedule-calendar .rbc-day-bg:hover {
  background: #f1fbff;
  cursor: pointer;
}

.tours-schedule-calendar .rbc-row-content {
  pointer-events: none;
}

.tours-schedule-calendar .rbc-today {
  color: #6cf;
}

.tours-schedule-calendar .red-border {
  border: 1px solid red;
}

.tours-schedule-calendar .rbc-day-bg.rbc-off-range-bg,
.tours-schedule-calendar .rbc-date-cell.rbc-off-range {
  visibility: hidden;
  pointer-events: none;
}

.tours-schedule-calendar .rbc-date-cell{
  pointer-events: none;
  cursor: default;
  text-align: left;
  padding-left: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #6C6C6C;
}
.tours-schedule-calendar .rbc-date-cell.rbc-now{
  color: #0996CE;
}

.tours-schedule-calendar .rbc-show-more{
  color: #0996CE;
  font-size: 12px;
}


.tours-schedule-calendar .rbc-off-range-bg {
  background: white !important;
}
/* rbc-date-cell rbc-off-range */
.tours-schedule-calendar .rbc-month-header {
  margin-bottom: 10px;

  color: var(--neutrals-75, #6c6c6c);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

.tours-schedule-calendar .rbc-header {
  text-align: left;
}

.tours-schedule-calendar .rbc-row-segment{
  padding: 0 3px 1px 3px;
}
.schedule-calendar .react-datepicker__input-container .react-datepicker__aria-live input {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}

.schedule-calendar .react-datepicker__header {
  background-color: white;
  border-bottom: unset;
  padding-top: 0;
}

.schedule-calendar .react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.schedule-calendar .react-datepicker__month {
  margin: 0;
  background-color: white;
}

.schedule-calendar .react-datepicker__month .react-datepicker__week {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.schedule-calendar .react-datepicker__day--outside-month {
  visibility: hidden;
}

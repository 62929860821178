@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.estateCardHorizontal:global(.ant-card .ant-card-cover img) {
  border-radius: 8px 0 0 8px;
  height: 200px;
  width: 280px;
}

.estateCardHorizontal:global(.ant-card .ant-card-cover img) {
  min-width: 180px;
}

.estateCardHorizontal:global(.ant-card) {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  animation: 1s ease-out 0s 1 opacityAnimation;
}

.estateCardHorizontal:global(.ant-card .ant-card-body) {
  min-width: 240px;
  padding: 16px;
  max-width: 100%;
  overflow: hidden;
  flex: 1;
}

.estateCardVertical:global(.ant-card .ant-card-body) {
  padding: 20px;
  max-height: 220px;
}

.estateCardVertical:global(.ant-card) {
  min-width: 250px;
  max-width: 296px;
  width: 100%;
  animation: .5s ease-out 0s 1 opacityAnimation;
}

.estateCardVertical:global(.ant-card .ant-card-cover img) {
  height: 200px;
}
